.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 1s ease-in;

  .modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 150%;
    transform: translate(-50%, -50%);
    width: 65vw;
    height: 35vw;
    transition: 2s top ease-in;

    .closeIcon {
      position: absolute;
      top: 2vw;
      right: 2vw;
      transition: transform 0.3s ease-in;
      cursor: pointer;
      z-index: 2;
      width: 1.5vw;

      &:hover {
        transform: rotate(180deg);
      }
    }

    .modalBorder {
      position: absolute;
      left: -0.1%;
      top: -0.1%;
      width: 100.2%;
      height: 100.2%;
      z-index: 1;
    }

    .modalBackground {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .typeWriter {
      position: relative;
      width: 68%;
      margin-top: 5vw;

      font-family: Orbitron, serif;
      color: rgb(128, 129, 128);
      font-size: 1.92vw;
      text-align: center;
    }

    .separator {
      position: relative;
      width: 55%;
      margin-top: 0.75vw;
    }

    .question {
      position: relative;
      font-size: 2.5vw;
      color: rgb(230, 230, 230);
      font-family: Orbitron, serif;
      text-align: center;
      margin-top: 0.945vw;
      margin-bottom: 3vw;
    }

    .buttonWrapper {
      position: absolute;
      bottom: 5vw;
      z-index: 123;

      &.left {
        transform: translateX(-50%);
        left: 40%;
      }

      &.right {
        transform: translateX(50%);
        right: 40%;
      }
    }
  }

  &.visible {
    opacity: 1;
    z-index: 123;

    .modal {
      top: 50%;
    }
  }

  @media (max-width: 1024px) {
    .modal {
      .typeWriter {
        margin-top: 12vw;
      }

      .separator {
        margin-top: 0.75vw;
      }

      .question {
        margin-top: 0.945vw;
        margin-bottom: -6vw;
      }
    }
  }
}